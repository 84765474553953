import request from "@/utils/request"

// 在线列表
export function listPage(data) {
  return request({
    url: "/communications/listPage",
    method: "post",
    data
  })
}
//查看用户聊天记录
export function Pagedetail(query) {
    return request({
      url: "/communications/detail",
      method: "get",
      params:query,
    })
  }
//回复小程序用户信息
export function sendMessage(data) {
    return request({
      url: "/communications/sendMessage",
      method: "post",
      data
    })
  }



